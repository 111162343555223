@charset "utf-8";@import "STYLES/variables";
@import "../../../scss/variables.scss";

.AboutWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  & div:nth-child(2n) {
    padding-left: clamp(0px, 34vw, 30%);
    @media screen and(max-width: 640px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  & > div:nth-child(1) {
    margin-top: 0;
    padding-top: 424px;
    @media screen and (max-width: 500px) {
      margin-bottom: 20px;
    }
    @media screen and (max-width: 768px) {
      padding-top: 0;
    }
  }

  & > div:nth-child(2) {
    padding-bottom: clamp(300px, 170vw, 1500px);
    margin-bottom: 400px;
    @media screen and (max-width: 640px) {
      padding-bottom: 700px;
      margin-bottom: 700px;
    }

    @media screen and (max-width: 440px) {
      margin-bottom: 500px;
    }
  }

  & > div:nth-child(3) {
    margin-bottom: clamp(200px, 60vw, 540px);

    @media screen and(max-width: 1200px) {
      margin-bottom: clamp(100px, 40vw, 540px);
    }

    @media screen and(max-width: 850px) {
      margin-bottom: clamp(50px, 40vw, 200px);
    }

    @media screen and (max-width: 640px) {
      margin-bottom: 150px;
    }
    @media screen and (max-width: 500px) {
      margin-bottom: clamp(0px, 300vw, 160px);
    }
  }
  & > div:nth-child(4) {
    padding-top: 500px;
    margin-bottom: 200px;
    margin-top: 200px;

    @media screen and (max-width: 1100px) {
      margin-top: 100px;
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 0;
      padding-top: 200px;
    }
  }

  & > div:nth-child(5) {
    padding-top: 500px;
    margin-bottom: 0;
    @media screen and (max-width: 640px) {
      padding-top: clamp(201px, 70vw, 400px);
      //margin-top: 0;
    }
  }

  &--item {
    position: relative;
    margin: 122px 0 122px 0;
    padding: 122px 0 122px 0;
    width: 70%;
    max-width: 1102px;

    h2 {
      position: relative;
      font-family: $fontTitle;
      font-weight: 400;
      @include adaptH4;
      line-height: 110%;
      text-transform: uppercase;
      color: #0d0c0c;
      max-width: 1102px;
      margin-bottom: clamp(16px, 5vw, 48px);
      z-index: 18;
      opacity: 0;
      transform: translateY(100px);
      transition: all 0.5s ease-out;
    }

    p {
      position: relative;
      @include adaptP;
      font-family: $fontText;
      font-weight: 400;
      line-height: 140%;
      color: #0d0c0c;
      padding-left: clamp(0px, 10vw, 230px);
      max-width: 672px;
      margin-bottom: clamp(16px, 5vw, 36px);
      z-index: 18;
      opacity: 0;
      transform: translateY(100px);
      transition: all 0.6s ease-out;
      will-change: transform, opacity;

      @media screen and(max-width: 426px) {

        padding-left: 14px;
      }
    }

    @media screen and(max-width: 640px) {
      width: 100%;
      margin-bottom: 0;
    }

    &.web-spa-active {
      h2 {
        opacity: 1;
        transform: translateY(0);
      }
      p {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &.api-active {
      h2 {
        opacity: 1;
        transform: translateY(0);
      }
      p {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &.services-active {
      h2 {
        opacity: 1;
        transform: translateY(0);
      }
      p {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &.events-active {
      h2 {
        opacity: 1;
        transform: translateY(0);
      }
      p {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &.gate-active {
      h2 {
        opacity: 1;
        transform: translateY(0);
      }
      p {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &-apiScene {
    overflow: hidden;
    position: absolute;
    width: 220%;
    height: 60%;
    left: clamp(40%, 41vw, 45%);
    top: 23%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 640px) {
      top: 46%;
      height: clamp(50%, 150vw, 80%);
      left: 80%;
    }
  }

  &-webScene {
    overflow: hidden;
    position: absolute;
    width: 220%;
    height: 75%;
    top: 5%;
    left: 60%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 1200px) {
      height: clamp(60%, 90vw, 90%);
    }

    @media screen and (max-width: 768px) {
      top: 100%;
      left: 65%;
      height: clamp(60%, 90vw, 90%);
    }

    @media screen and (max-width: 768px) {
      left: 65%;
      height: clamp(60%, 90vw, 90%);
    }
    @media screen and (max-width: 640px) {
      //height: clamp(1%, 10vw, 50%);
      top: 98%;
      left: 40%;
    }
    @media screen and (max-width: 380px) {
      height: clamp(40%, 90vw, 90%);
      left: 40%;
    }
  }

  &-servicesScene {
    overflow: hidden;
    position: absolute;
    width: 200%;
    height: clamp(150%, 240vw, 300%);
    top: -15%;
    left: 75%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 830px) {
      left: 85%;
    }
    @media screen and (max-width: 640px) {
      height: clamp(150%, 480vw, 300%);
      left: 60%;
    }
    @media screen and (max-width: 500px) {
      top: 25%;
    }
  }

  &-eventsScene {
    overflow: hidden;
    position: absolute;
    width: 200%;
    height: clamp(120%, 130vw, 150%);
    bottom: 45%;
    left: -20%;

    @media screen and (max-width: 1200px) {
      bottom: 50%;
    }

    @media screen and (max-width: 1000px) {
      left: -12%;
    }

    @media screen and (max-width: 850px) {
      bottom: clamp(10%, 47vw, 47%);
      left: -15%;
    }
    @media screen and (max-width: 640px) {
      height: clamp(100%, 190vw, 150%);
      bottom: clamp(14%, 20vw, 20%);
      left: 5%;
    }
    @media screen and (max-width: 500px) {
      height: clamp(130%, 290vw, 180%);
      left: -8%;
      bottom: 22%;
    }
    @media screen and (max-width: 380px) {
      left: -20%;
      bottom: 30%;
    }
    @media screen and (max-width:360px) {
      bottom: 40%;
    }
  }

  &-gateScene {
    overflow: hidden;
    position: absolute;
    width: 230%;
    height: 120%;
    top: -50%;
    left: -50%;

    @media screen and (max-width: 640px) {
      height: clamp(70%, 180vw, 100%);
      top: -40%;
      left: -50%;
    }
    @media screen and (max-width: 380px) {
      height: clamp(70%, 180vw, 100%);
      left: -60%;
      top: -35%;
    }
    @media screen and (max-width: 330px) {
      top: -35%;
    }
  }
}

.circle-web {
  &-red {
    position: absolute;
    width: clamp(500px, 100vw, 1500px);
    height: clamp(500px, 100vw, 1500px);
    left: 25%;
    top: 30%;
    transform: translate(-50%, -50%);
  }

  &-white {
    position: absolute;
    width: clamp(450px, 100vw, 1500px);
    height: clamp(450px, 100vw, 1500px);
    left: 55%;
    top: 55%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 769px) {
    &-red {
      left: 25%;
      top: 100%;
    }
    &-white {
      left: clamp(60%, 60vw, 90%);
      top: 110%;
    }
  }
}

.circle-api {
  &-red {
    position: absolute;
    width: clamp(900px, 100vw, 1500px);
    height: clamp(900px, 100vw, 1500px);
    left: 25%;
    top: 30%;
    transform: translate(-50%, -50%);
  }

  &-white {
    position: absolute;
    width: clamp(1000px, 100vw, 1500px);
    height: clamp(1000px, 100vw, 1500px);
    left: 45%;
    top: 25%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 640px) {
    &-red {
      left: 25%;
      top: 50%;
    }
    &-white {
      left: 47%;
      top: 40%;
    }
  }
}

.circle-services {
  &-left {
    position: absolute;
    width: clamp(500px, 100vw, 1200px);
    height: clamp(500px, 100vw, 1300px);
    left: 15%;
    top: -20%;
    transform: translate(-50%, -50%);
  }

  &-right {
    position: absolute;
    width: clamp(500px, 100vw, 1200px);
    height: clamp(500px, 100vw, 1300px);
    left: 145%;
    top: 25%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 640px) {
    &-left {
      left: 15%;
      top: -10%;
    }
    &-right {
      left: 100%;
      top: 10%;
    }
  }
}

.circle-events {
  &-green {
    position: absolute;
    width: clamp(500px, 100vw, 1200px);
    height: clamp(500px, 100vw, 1300px);
    left: 40%;
    top: 80%;
    transform: translate(-50%, -50%);
  }

  &-white {
    position: absolute;
    width: clamp(400px, 100vw, 1100px);
    height: clamp(500px, 100vw, 1100px);
    left: 58%;
    top: 65%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 640px) {
    &-green {
      left: 30%;
      top: 60%;
    }
    &-white {
      left: 40%;
      top: 75%;
    }
  }
  @media screen and (max-width: 500px) {
    &-green {
      left: 30%;
      top: 40%;
    }
    &-white {
      left: 50%;
      top: 55%;
    }
  }
}
