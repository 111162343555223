@charset "utf-8";@import "STYLES/variables";
.item{
  &, img{
    width:100%;
    height:100%;
    display:block;
    object-fit:cover;
  }
}
.auto{
  composes: item;
  &, img{
    width:auto;
    height:auto;
    max-width:100%;
  }
}