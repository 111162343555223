@charset "utf-8";@import "STYLES/variables";
@import "../../../scss/variables.scss";

.menu-wrapper{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  visibility: hidden;
  transform: translateX(100%);
  background-color: #fff;
  z-index: 21;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.7s;
  transform-style: preserve-3d;
  will-change: transform;
  overflow: hidden;

  &.open{
    visibility: visible;
    transform: none;
  }

  .menu-side-text{
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    position: absolute;
    left: 10px;
    bottom:clamp(20%, 20vw, 30%);
    font-family: $fontText;
    font-size: clamp(16px,4vw,40px);
    text-transform: lowercase;
  }

  &-list{
    position: relative;


    h2{
      position: relative;
      z-index: 31;
      font-weight: 400;
      font-size: clamp(20px,2.5vw,48px);
      line-height: 110%;
      font-family:$fontTitle ;
      text-transform: uppercase;
      margin-bottom: clamp(32px,6vw,64px);
      cursor: pointer;
      transform: translateX(0);

      @for $i from 1 through 6 {
        &:nth-child(#{$i}) {
          opacity: 0;
          transform: translateX(0);
          transition:  opacity 0.5s ease $i * 100ms, transform 0.5s ease-out $i * 100ms, color 0.2s ;
          -webkit-transition:  opacity 0.5s ease $i * 100ms, transform 0.5s ease-out $i * 100ms, color 0.33s;
          will-change: transform, opacity;
        }
      }

      &:last-child{
        margin-bottom: 0;
      }
    }

    &.active {
      h2{
        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            opacity: 1;
            transform: translateX($i * 6%);

            &:hover {
              color: #7C7DFF;
            }
          }
        }

      }
    }
  }

  &-close{
    position: absolute;
    right: 41px;
    top: 43px;
    cursor: pointer;
  }

  &-scene{
    position: absolute;
    pointer-events: none;
    width: 200%;
    height:95%;
    left: 53%;
    overflow: hidden;

  }
  &-circle{
    position: absolute;
    width: 20%;
    height: 20%;
    left:50%;
    top:50%;

  }
}

.scenesCanvas{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 30;
  pointer-events: none;
  overflow: hidden;
}
