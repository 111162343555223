@charset "utf-8";@import "STYLES/variables";
@import "../../../scss/variables.scss";

.eyesBlock{
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 400px 114px 450px 0;
  margin: 0 0 250px 0;
  overflow-y: hidden;

  @media screen and(max-width: 1150px){
    padding-left: 80px;
    padding-bottom: clamp(180px, 30vw, 450px);
    padding-top: clamp(180px, 30vw, 400px);
  }
  @media screen and(max-width: 1160px){
    padding-right: 0;
    padding-left: 0;
  }
  @media screen and(max-width: 768px){
   margin-bottom: 0;
  }

  &-scene{
    position: absolute;
    overflow: hidden;
    width: 130%;
    height: 110%;
    top: 50%;
    left: clamp(40%,50vw,55%);
    transform: translate(-50%,-50%);

    @media screen and (max-width: 1330px) {
      left: clamp(40%,45vw,55%);
    }
  }

  &-circles{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: 100%;
    transform: translate(-50%, -50%);
    overflow: hidden;


    &-blue{
      position: absolute;
      height: clamp(70%, 100vw,130%);
      width: 120%;
      top: 50%;
      left: 65%;
      transform: translate(-50%, -50%);
    }
    &-red{
      position: absolute;
      height: clamp(70%, 100vw,130%);
      width: 120%;
      top: 35%;
      left: 40%;
      transform: translate(-50%, -50%);
    }
  }

}
.text{
  position: relative;
  font-family: $fontTitle;
  font-weight: 400;
  @include  adaptH2;
  line-height: 110%;
  color: #0D0C0C;
  max-width: 920px;
  z-index: 18;

  @media screen and(max-width:1050px ) {
    max-width: 870px;
  }

  @media screen and (min-width: 1023px){
      span{
        display: none;
      }
  }
}