@charset "utf-8";@import "STYLES/variables";
.menu-icon{
  svg{
    fill: white;
  }

  &:before{
    content: "";
    background: url("MEDIA/images/menuRect.svg");
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    right:12px;
    top: 12px;
    transition: top 0.2s,right 0.2s ease-out;

    @media screen and (max-width: 769px) {
      top: 12px;
    }
  }


  &:hover:before{
    top: 14px;
    right:14px;
    @media screen and (max-width: 769px) {
      top: 14px;
    }
  }
  @media screen and (max-width: 769px) {
    top: 10px;
  }


  &:active:before{
    top: 14px;
    right:14px;
    @media screen and (max-width: 769px) {
      top: 14px;
    }
  }
  @media screen and (max-width: 769px) {
    top: 10px;
  }
}

.menu-icon-wrapper{
  position: fixed;
  display: flex;
  cursor: pointer;
}

.menu-icon-wrapper-wrapper{

  position: absolute;
  mix-blend-mode: difference;
  z-index: 20;
  right:61px;
  @media screen and (max-width: 768px) {
    right: 36px;
  }
  @media screen and (max-width: 500px) {
    top: 20px;
  }

}
