@charset "utf-8";@import "STYLES/variables";
.section{
  composes:margin2 from global;

  .primitives{
    max-width:898px;
    margin:0 auto;
  }
  h2, h3, h4, p, ul{
    max-width:none;
  }
  blockquote{
    max-width:780px;
  }
}

.primitives{
  max-width:838px;
  //padding-right:5%;

  h2, .h2{
    margin-bottom:24px;
  }
  & + :global(.button){
    margin-top:56px;
  }
}
@media screen and (min-width: 768px) {
  .primitives{
    //h1, .h1{
    //  &:first-child{
    //    margin-top:-15px;
    //  }
    //}
    h2, .h2{
      margin-bottom:40px;
    }
    & + :global(.button){
      margin-top:80px;
    }
  }
}
