@charset "utf-8";@import "STYLES/variables";
@import "../../../scss/variables.scss";

.ExtraWrapper{
  overflow: hidden !important;
  position: relative;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 122px;

  h2{
    position: relative;
    font-family: $fontTitle;
    font-weight: 400;
    z-index:18;
    @include adaptH2;
    line-height: 110%;
    color: #0D0C0C;
    max-width: 900px;
    margin-bottom: clamp(16px, 5vw, 48px);
    margin-right: 115px;

    @media screen and (max-width: 640px) {
      margin-bottom: 100px;
    }
    @media screen and (max-width: 440px) {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 640px){
    padding-top:50px;
    margin-top: -50px;
  }

  .ExtraItemList{
    box-sizing: border-box;
    width: 50%;
    max-width: 668px;
    padding: 350px 0 0 0;


    .ExtraItem{
      position: relative;
      margin-bottom: 360px;
      h4{
        font-family: $fontTitle;
        font-weight: 400;
        @include adaptH4;
        line-height: 110%;
        color: #0D0C0C;
        margin-bottom: 24px;
      }
      p{
        font-family: $fontText;
        font-weight: 400;
        @include adaptP;
        color: #0D0C0C;
        margin-bottom: clamp(16px, 5vw, 36px);
      }
      &:last-child{
        margin-bottom: 0;
      }

    }

    @media sceen and (max-width: 1023px) {
      align-items: center;
    }
    @media screen and (max-width: 1023px) {
      min-width: 100%;
    }


  }
}

.TitleCircleGreen{
  position: absolute;
  width: 120%;
  height: clamp(500px, 100vw, 1400px);
  top: 7%;
  left: 75%;
  transform: translate(-50%, -50%);
}
.TitleCircleRed{
  position: absolute;
  width: 120%;
  height: clamp(500px, 100vw, 1400px);
  top: 5%;
  left: 40%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 640px){
  .TitleCircleGreen{
    top: 3%;
  }
  .TitleCircleRed{
    top: 2%;
  }
}



.Scene{
  overflow: hidden;
  width: 120%;
  height: clamp(230px, 40vw, 350px);
  position: absolute;
  top: -265px;
  left: -10%;

  @media screen and (min-width: 700px){
    top: -335px;
  }

  @media screen and(min-width: 1023px) {
    display: none;
  }
}

.ExtraItemCircle{
  width: 120%;
  left: -10%;
  height: clamp(500px, 70vw, 800px);
  position: absolute;
  top: -410px;

  @media screen and (min-width: 700px){
    top: -480px;
  }

  @media screen and(min-width: 1023px) {
    display: none;
  }

}

.ExtraItemCircleCloud{
  width: 120%;
  left: -10%;
  height: clamp(500px, 70vw, 800px);
  position: absolute;
  top: clamp(-50%, 100vw,-130%);
  @media screen and(min-width: 1023px) {
    display: none;
  }
}