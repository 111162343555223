@charset "utf-8";@import "STYLES/variables";
@import "../../../scss/variables.scss";

.wrapper{
  position: relative;
  margin: 0 auto;
  padding: 43px 41px 0 41px ;
  font-family: $fontTitle;
  max-width: 1620px;
  min-width: 288px;
  
  h1{
    font-weight: 600;
    font-size: 128px;
    line-height: 90%;
  }

  @media screen and (min-width: 1441px) {
      max-width: 1360px;
  }
  @media screen and(max-width: 769px){
    padding:30px 16px 26px 16px ;
  }

  & > div{
    overflow-x: hidden;
  }
}


.scenesCanvas{
  width: 100vw;
  height: 150vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 14;
  pointer-events: none;
  overflow: hidden;
}

