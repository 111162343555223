@charset "utf-8";@import "STYLES/variables";
@import "../../../scss/variables.scss";

.wrapper{
  position: relative;
  display: inline-block;


  @media screen and (max-width: 900px) {
    transform: scale(0.8);
    margin-left: -25px;
    margin-top: -10px;
  }

  @media screen and (max-width: 500px) {
    transform: scale(0.7);
    margin-left: -40px;
    margin-top: -20px;
  }


}

.long{
  position: relative;
  display: inline-block;

  @media screen and (max-width: 900px){
    transform: scale(0.8);
    margin-left: -32px;
    margin-top: -10px;
  }
  @media screen and (max-width: 500px){
    transform: scale(0.65);
    margin-left: -60px;
    margin-top: -10px;
  }

  &-button{
    border-radius: 50px;
    cursor: pointer;
  }

  &-text{
    width: 100%;
    mix-blend-mode: difference;
    position: absolute;
    display: inline-block;
    top: 45%;
    left: 75%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    font-family: $fontText;
    font-size: 32px;
    pointer-events: none;
    color:white;

    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  &-button{
    border-radius: 50px;
    cursor: pointer;
    mix-blend-mode: normal;

    rect{
      border-radius: 50px;
      padding: 5px;
      fill: white;
    }

    circle{
      fill: black;
      animation: moveLong 4s infinite cubic-bezier(.6,.23,.59,.65);
      transition: all 0.5s ease;
      transform: rotate(0deg) translateX(40px) rotate(0deg);
    }

    &:hover, :active{
      circle{
        r:300px;
      }
    }
  }

  &-button2{
    position: absolute;
    left: -5px;
    top: -8px;
    border-radius: 50px;
    cursor: pointer;
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.5s;

    circle{
      fill: black;
      animation: moveEmptyLong 4s infinite cubic-bezier(.6,.23,.59,.65);
    }
  }

  &-button:hover + &-button2{
    opacity: 0;
  }



}

.text{
  mix-blend-mode: difference;
  position: absolute;
  display: inline-block;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 400;
  font-family: $fontText;
  font-size: 32px;
  pointer-events: none;
  color:white;

  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.button{
  border-radius: 50px;
  cursor: pointer;

  rect{
      fill: #FFFFFF;
    border-radius: 50px;
    padding: 5px;
    }

    circle{
      fill: black;
      animation: move 3s infinite cubic-bezier(.7,.63,.45,.84);
      transition: all 0.5s ease;
      transform: rotate(0deg) translateX(40px) rotate(0deg);
    }

    &:hover, :active{
      circle{
        r:300px;
      }
  }
}

.button2{
  position: absolute;
  left: -5px;
  top: -8px;
  border-radius: 50px;
  cursor: pointer;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.5s;

  circle{
    fill: black;
    animation: moveEmpty 3s infinite cubic-bezier(.7,.63,.45,.84);
  }
}

.button:hover + .button2{
  opacity: 0;
}






@keyframes moveEmpty {

  $cy: 48px;
  $cxL: 47px;
  $cxR: 197px;
  $translate: 39px;

  0% {
    cx: $cxL;
    cy: $cy;
    transform: rotate(0deg) translateY($translate) rotate(0deg);
  }
  25% {
    cx: $cxL;
    cy: $cy;
    transform: rotate(180deg) translateY($translate) rotate(-180deg);
  }
  50% {
    cx: $cxR;
    cy: $cy;
    transform: rotate(180deg) translateY($translate) rotate(-180deg);
  }
  75% {
    cx: $cxR;
    cy: $cy;
    transform: rotate(360deg) translateY($translate) rotate(-360deg);
  }
  100% {
    cx: $cxL;
    cy: $cy;
    transform: rotate(360deg) translateY($translate) rotate(-360deg);
  }
}

@keyframes move {

  $cy: 43px;
  $cxL: 43px;
  $cxR: 208px;
  $translate: 42px;

  0% {
    cx: $cxL;
    cy: $cy;
    transform: rotate(0deg) translateY($translate) rotate(0deg);
  }
  25% {
    cx: $cxL;
    cy: $cy;
    transform: rotate(180deg) translateY($translate) rotate(-180deg);
  }
  50% {
    cx: $cxR;
    cy: $cy;
    transform: rotate(180deg) translateY($translate) rotate(-180deg);
  }
  75% {
    cx: $cxR;
    cy: $cy;
    transform: rotate(360deg) translateY($translate) rotate(-360deg);
  }
  100% {
    cx: $cxL;
    cy: $cy;
    transform: rotate(360deg) translateY($translate) rotate(-360deg);
  }
}




@keyframes moveEmptyLong {

  $cy: 52px;
  $cxL: 50px;
  $cxR: 305px;
  $translate: 43px;

  0% {
    cx: $cxL;
    cy: $cy;
    transform: rotate(0deg) translateY($translate) rotate(0deg);
  }
  25% {
    cx: $cxL;
    cy: $cy;
    transform: rotate(180deg) translateY($translate) rotate(-180deg);
  }
  50% {
    cx: $cxR;
    cy: $cy;
    transform: rotate(180deg) translateY($translate) rotate(-180deg);
  }
  75% {
    cx: $cxR;
    cy: $cy;
    transform: rotate(360deg) translateY($translate) rotate(-360deg);
  }
  100% {
    cx: $cxL;
    cy: $cy;
    transform: rotate(360deg) translateY($translate) rotate(-360deg);
  }
}

@keyframes moveLong {

  $cy: 43px;
  $cxL: 43px;
  $cxR: 300px;
  $translate: 42px;

  0% {
    cx: $cxL;
    cy: $cy;
    transform: rotate(0deg) translateY($translate) rotate(0deg);
  }
  25% {
    cx: $cxL;
    cy: $cy;
    transform: rotate(180deg) translateY($translate) rotate(-180deg);
  }
  50% {
    cx: $cxR;
    cy: $cy;
    transform: rotate(180deg) translateY($translate) rotate(-180deg);
  }
  75% {
    cx: $cxR;
    cy: $cy;
    transform: rotate(360deg) translateY($translate) rotate(-360deg);
  }
  100% {
    cx: $cxL;
    cy: $cy;
    transform: rotate(360deg) translateY($translate) rotate(-360deg);
  }
}

