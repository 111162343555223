@charset "utf-8";@import "STYLES/variables";
.wrapper{
  position:fixed;
  left:10px;
  bottom:20px;
  right:10px;
  z-index:10000;
  overflow:hidden;
  pointer-events:none;
}

.item{
  width:100%;
  max-width:334px;
  margin-top:20px;
  box-shadow:0 1px 4px rgba(2, 16, 34, 0.15);
  pointer-events:auto;
  position:relative;
  background-color:#FFF;

  &.error{
    color:$red;
  }
}
.inner{
  max-width:300px;
  padding:30px;
  box-sizing:border-box;
}

@media screen and (min-width: 768px) {
  .wrapper{
    left:65px;
    bottom:65px;
    right:65px;
  }
}