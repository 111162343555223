@charset "utf-8";@import "STYLES/variables";
@import "../../../scss/variables.scss";

.wrapper{

  position: absolute;
  top: clamp(1400px, 120vw ,1550px);
  margin-top: -166px;
  opacity: 1;
  left: 25px;
  transition: opacity 0.25s, left 0.25s;
  height: 90%;
  overflow-x:clip !important;

  .text{
    writing-mode: vertical-rl;
    font-weight: 400;
    font-family: $fontText;
    font-size: 40px;
    line-height: 110%;
    text-transform: lowercase;
    color: #0D0C0C;
    transform: rotate(180deg);
    @media screen and(max-width: 1023px) {
      display: none;
    }
  }

  &.disappear{
    opacity: 0;
    left: -20px;
  }

  &-fixed{
    position: sticky;
    transition: opacity 0.25s, left 0.25s;
    top: 50%;
    transform: translate(0,-50%);
    &.disappear{
      opacity: 0;
    }

  }
}


.SceneWrapper{
    position: absolute;
    top: 50%;
    left: 23vw;
    width: 45vw;
    max-width: 600px;
    height: 150%;
    max-height: 0;
    visibility: hidden;
    transition: max-height 500ms   ease-in-out;
    transform: translate(-50%, -50%);

   &.appear{
     visibility: visible;
     max-height: 150%;
   }

  @media screen and (max-width: 1023px){
    display: none;
  }
  @media screen and (min-width: 1440px){
    left: 340px;
  }
}




.SceneWrapperScene{
  overflow: hidden;
  position: absolute;
  width: 100%;
  height:clamp(70%, 40vw, 100%);
  opacity: 0;
  top: 50%;
  transform: translate(0, -50%);
  visibility: hidden;
  transition: all 200ms;
}
