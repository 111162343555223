@charset "utf-8";
//@import "fonts";

//functions
@function hashReplace($colour) {
  @if str-slice('#{$colour}', 0, 1) == '#' {
    @return '%23' + str-slice('#{$colour}', 2, -1)
  }
  @return $colour;
}


//defaults
$defaultText: 'Roboto', sans-serif;
$defaultTextColor: #000;

$selectionColor: #FFF;
$selection: $defaultTextColor;







//fonts
$title: 'TT Squares Condensed', sans-serif;
$fontTitle: 'Redcollar', sans-serif;
$fontText: "PT Root UI", sans-serif;



//colors
$red: red;



//easing
$ease: ease;
$easeOut: cubic-bezier(0.19, 1, 0.22, 1);
$easeIn: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeInOut: cubic-bezier(0.35,0,0,1.01);



//patterns
@mixin borderRadius($radius: 16px) {
  border-radius:$radius;
}
@mixin dropShadow($radius: 16px) {
  box-shadow:0 0 #{$radius} rgba(0, 0, 0, 0.08);
}



//mixins
@mixin stroke($color: #000, $size: 1px) {
  text-shadow:
      -#{$size} -#{$size} 0 $color,
      0         -#{$size} 0 $color,
      #{$size}  -#{$size} 0 $color,
      #{$size}   0        0 $color,
      #{$size}   #{$size} 0 $color,
      0          #{$size} 0 $color,
      -#{$size}  #{$size} 0 $color,
      -#{$size}  0        0 $color;
}
@mixin pseudo($el: before, $height: 100%) {
  &:#{$el} {
    content:'';
    width:0;
    height:$height;
    display:inline-block;
    vertical-align:middle;
  }
}

@mixin rect($paddingBottom: 100%) {
  &:before {
    content:'';
    width:100%;
    display:block;
    padding-bottom:$paddingBottom;
  }
}


@mixin justify() {
  text-align:justify;
  font-size:0;

  &:after{
    content:'';
    width:100%;
    height:0;
    display:inline-block;
  }
}






@mixin fb($width, $height, $fill, $color) {
  background:unquote($color)url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='#{$width}' height='#{$height}' viewBox='0 0 20 20'><path fill='#{hashReplace($fill)}' d='M6,7.1h1.7V5.4c0-0.8,0-1.9,0.5-2.6C8.8,2,9.6,1.5,10.9,1.5c2.1,0,3,0.3,3,0.3l-0.4,2.6c0,0-0.7-0.2-1.4-0.2c-0.7,0-1.2,0.2-1.2,0.9v2h2.7l-0.2,2.5h-2.5v8.8H7.7V9.7H6V7.1z'/></svg>") no-repeat center center;
}
@mixin tw($width, $height, $fill, $color) {
  background:unquote($color)url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='#{$width}' height='#{$height}' viewBox='0 0 20 20'><path fill='#{hashReplace($fill)}' d='M17.9,5.1c-0.6,0.3-1.2,0.4-1.9,0.5c0.7-0.4,1.2-1,1.4-1.8c-0.6,0.4-1.3,0.6-2.1,0.8c-0.6-0.6-1.4-1-2.4-1c-1.8,0-3.3,1.5-3.3,3.3c0,0.3,0,0.5,0.1,0.7C7.2,7.4,4.8,6.1,3.2,4.1c-0.3,0.5-0.4,1-0.4,1.6c0,1.1,0.6,2.1,1.4,2.7c-0.5,0-1-0.2-1.5-0.4v0c0,1.6,1.1,2.9,2.6,3.2c-0.3,0.1-0.6,0.1-0.9,0.1c-0.2,0-0.4,0-0.6-0.1c0.4,1.3,1.6,2.2,3,2.3c-1.1,0.9-2.5,1.4-4,1.4c-0.3,0-0.5,0-0.8,0c1.4,0.9,3.2,1.5,5,1.5c6,0,9.3-5,9.3-9.3c0-0.1,0-0.3,0-0.4C16.9,6.3,17.5,5.7,17.9,5.1'/></svg>") no-repeat center center;
}
@mixin vk($width, $height, $fill, $color) {
  background:unquote($color)url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='#{$width}' height='#{$height}' viewBox='0 0 20 20'><path fill='#{hashReplace($fill)}' d='M9.8,15.2h1.1c0,0,0.3,0,0.5-0.2c0.2-0.2,0.2-0.5,0.2-0.5s0-1.5,0.7-1.7c0.7-0.2,1.6,1.4,2.5,2.1c0.7,0.5,1.3,0.4,1.3,0.4l2.5,0c0,0,1.3-0.1,0.7-1.1C19.3,14,19,13.4,17.5,12c-1.6-1.4-1.4-1.2,0.5-3.7c1.2-1.5,1.6-2.4,1.5-2.8c-0.1-0.4-1-0.3-1-0.3l-2.9,0c0,0-0.2,0-0.4,0.1C15.1,5.4,15,5.6,15,5.6S14.6,6.8,14,7.8c-1.3,2.1-1.8,2.2-2,2.1c-0.5-0.3-0.4-1.2-0.4-1.9c0-2,0.3-2.9-0.6-3.1c-0.3-0.1-0.5-0.1-1.3-0.1c-1,0-1.9,0-2.4,0.2C6.9,5.2,6.7,5.5,6.8,5.5c0.2,0,0.6,0.1,0.8,0.4C8,6.3,8,7.2,8,7.2s0.2,2.4-0.4,2.7c-0.4,0.2-0.9-0.2-2.1-2.1c-0.6-1-1-2.1-1-2.1S4.4,5.5,4.3,5.4C4.1,5.2,3.8,5.2,3.8,5.2H1.1c0,0-0.4,0-0.6,0.2c-0.1,0.2,0,0.5,0,0.5s2.1,4.9,4.5,7.3C7.3,15.4,9.8,15.2,9.8,15.2z'/></svg>") no-repeat center center;
}
@mixin ok($width, $height, $fill, $color) {
  background:unquote($color)url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='#{$width}' height='#{$height}' viewBox='0 0 20 20'><path fill='#{hashReplace($fill)}' d='M10,3.7c1.1,0,1.9,0.9,1.9,1.9c0,1.1-0.9,1.9-1.9,1.9c-1.1,0-1.9-0.9-1.9-1.9C8.1,4.6,8.9,3.7,10,3.7L10,3.7z M10,10.3c2.6,0,4.6-2.1,4.6-4.6C14.6,3.1,12.6,1,10,1C7.4,1,5.4,3.1,5.4,5.6C5.4,8.2,7.4,10.3,10,10.3 M11.9,14.1c1-0.2,1.9-0.6,2.7-1.1c0.6-0.4,0.8-1.2,0.4-1.9c-0.4-0.6-1.2-0.8-1.9-0.4c0,0,0,0,0,0c-1.9,1.2-4.4,1.2-6.3,0c-0.6-0.4-1.5-0.2-1.9,0.4c0,0,0,0,0,0c-0.4,0.6-0.2,1.5,0.4,1.9c0.8,0.5,1.7,0.9,2.7,1.1l-2.6,2.6c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0l0,0L10,16l2.6,2.6c0.5,0.5,1.4,0.5,1.9,0c0,0,0,0,0,0c0.5-0.5,0.5-1.4,0-1.9c0,0,0,0,0,0L11.9,14.1'/></svg>") no-repeat center center;
}
@mixin ig($width, $height, $fill, $color) {
  background:unquote($color)url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='#{$width}' height='#{$height}' viewBox='0 0 20 20'><circle fill='#{hashReplace($fill)}' cx='10' cy='10' r='2.5'/><path fill='#{hashReplace($fill)}' d='M17.5,6.9c0-0.8-0.2-1.3-0.3-1.8c-0.2-0.5-0.4-0.9-0.9-1.3c-0.4-0.4-0.8-0.7-1.3-0.9c-0.5-0.2-1-0.3-1.8-0.3s-1.1,0-3.1,0s-2.3,0-3.1,0S5.7,2.8,5.2,2.9c-0.6,0.2-1,0.4-1.4,0.9C3.3,4.2,3.1,4.6,2.9,5.1c-0.2,0.5-0.3,1-0.3,1.8s0,1.1,0,3.1s0,2.3,0,3.1s0.2,1.3,0.3,1.8c0.2,0.5,0.4,0.9,0.9,1.3c0.4,0.4,0.8,0.7,1.3,0.9c0.5,0.2,1,0.3,1.8,0.3s1.1,0,3.1,0s2.3,0,3.1,0s1.3-0.2,1.8-0.3c0.5-0.2,0.9-0.4,1.3-0.9c0.4-0.4,0.7-0.8,0.9-1.3c0.2-0.5,0.3-1,0.3-1.8s0-1.1,0-3.1S17.5,7.7,17.5,6.9z M10,13.9c-2.1,0-3.9-1.7-3.9-3.9S7.9,6.1,10,6.1s3.9,1.7,3.9,3.9S12.1,13.9,10,13.9z M14,6.9c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S14.5,6.9,14,6.9z'/></svg>") no-repeat center center;
}
@mixin yt($width, $height, $fill, $color) {
  background:unquote($color)url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='#{$width}' height='#{$height}' viewBox='0 0 20 20'><path fill='#{hashReplace($fill)}' d='M14.3,4.6H5.7c-1.8,0-3.2,1.4-3.2,3.2v4.3c0,1.8,1.4,3.2,3.2,3.2h8.6c1.8,0,3.2-1.4,3.2-3.2V7.9C17.5,6.1,16.1,4.6,14.3,4.6z M8.7,12.7V7.3l3.5,2.7L8.7,12.7z'/></svg>") no-repeat center center;
}
@mixin pn($width, $height, $fill, $color) {
  background:unquote($color)url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='#{$width}' height='#{$height}' viewBox='0 0 20 20'><path fill='#{hashReplace($fill)}' d='M10.4,1.6c-4.6,0-6.9,3.3-6.9,6c0,1.7,0.6,3.1,2,3.7c0.2,0.1,0.4,0,0.5-0.2c0-0.2,0.1-0.6,0.2-0.8c0.1-0.2,0-0.3-0.1-0.5C5.6,9.3,5.4,8.7,5.4,7.9c0-2.4,1.8-4.6,4.7-4.6c2.6,0,4,1.6,4,3.7c0,2.8-1.2,5.1-3.1,5.1c-1,0-1.8-0.8-1.5-1.9c0.3-1.2,0.9-2.5,0.9-3.4c0-0.8-0.4-1.4-1.3-1.4c-1,0-1.9,1.1-1.9,2.5c0,0.9,0.3,1.5,0.3,1.5s-1.1,4.5-1.2,5.3c-0.4,1.6-0.1,3.5,0,3.7c0,0.1,0.2,0.1,0.2,0.1c0.1-0.1,1.3-1.6,1.7-3.2c0.1-0.4,0.7-2.7,0.7-2.7c0.3,0.6,1.3,1.2,2.4,1.2c3.1,0,5.2-2.8,5.2-6.6C16.5,4.3,14.1,1.6,10.4,1.6'/></svg>") no-repeat center center;
}
@mixin in($width, $height, $fill, $color) {
  background:unquote($color)url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='#{$width}' height='#{$height}' viewBox='0 0 20 20'><path fill='#{hashReplace($fill)}' d='M19,11.9v6.7h-3.9v-6.2c0-1.6-0.6-2.6-2-2.6c-1.1,0-1.7,0.7-2,1.4c-0.1,0.2-0.1,0.6-0.1,0.9v6.5H7.2c0,0,0.1-10.5,0-11.6h3.9v1.6c0,0,0,0,0,0h0v0c0.5-0.8,1.4-1.9,3.5-1.9C17.1,6.7,19,8.4,19,11.9z M3.2,1.4C1.9,1.4,1,2.3,1,3.4c0,1.1,0.8,2,2.1,2h0c1.3,0,2.2-0.9,2.2-2C5.3,2.3,4.5,1.4,3.2,1.4z M1.2,18.6h3.9V7H1.2V18.6z'/></svg>") no-repeat center center;
}


@mixin adaptH1{
  font-size: clamp(30px, 8vw, 130px);
}

@mixin adaptH2{
  font-size: clamp(24px, 4.5vw, 48px);
  @media screen and (max-width: 769px) {
    margin-right: 0;
  }
}

@mixin adaptH4{
  font-size: clamp(20px, 4vw, 40px);
}

@mixin adaptP{
  font-size: clamp(14px, 2.2vw, 24px);
  line-height: 140%;
}