@charset "utf-8";@import "STYLES/variables";
@import "../../../scss/variables.scss";

.TitleBlock{
  position: relative;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 500px){
    margin-top: 60px;
  }



}

.TitleBlockTitle{
  margin-bottom: clamp(426px, 2vw,500px) ;

  h1{
    margin-bottom:clamp(16px,5vw ,32px) ;
    font-family: $fontTitle;
    max-width: 1134px;
    font-weight: 400;
    font-size: clamp(40px, 8vw, 130px);
    line-height: 90%;
    color: #0D0C0C;

    @media screen and (min-width: 1440px) and (max-height: 800px){
      font-size: 100px;
    }

  }

  p{
    font-family: $fontText;
    font-style: normal;
    font-weight: 400;
    font-size: clamp(16px,2.5vw,40px);
    @media screen and (min-width: 1440px) and (max-height: 800px){
      font-size: 30px;
    }
  }
}

.Content{
  max-width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-right:165px ;
  @media screen and(max-width: 1160px){
    padding-right: 55px;
  }
  @media screen and(max-width:1050px){
    padding-right: 0;
  }
  @media screen and(max-width: 710px){
    padding-right: 0;
    align-items: flex-start;
  }
}

.ContentText{
  max-width: 900px;
  position: relative;
  @media screen and(max-width: 710px){
   max-width: 500px;
  }
  @media screen and(max-width: 710px){
    font-size: 20px;
  }
}

.textPrimary{
  font-family: $fontTitle;
  font-weight: 400;
  @include adaptH2;
  line-height: 110%;
  color: #0D0C0C;
  padding-top: clamp(100px, 30vw, 400px);
  padding-bottom: clamp(20px, 5vw, 40px);


}

.textSecondary{
  font-family:$fontText;
  font-style: normal;
  max-width: 870px;
  font-weight: 400;
  @include adaptP;
  line-height: 140%;
  color: #0D0C0C;
}

.TitleBlockSide{
    display: block;
    position: absolute;
    writing-mode: vertical-rl;
    font-weight: 400;
    font-family: $fontText;
    font-size:clamp(16px,5vw ,32px);
    line-height: 110%;
    text-transform: lowercase;
    color: #0D0C0C;
    transform: rotate(180deg);
    top: 0;
    margin-top: 410px;

  @media screen and (min-width:1023px) {
    display: none;
  }

}

.TitleBlockScene{
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  width: 120%;
  height: clamp(25%, 110vw ,100%);
  top: clamp(45%, 90vw ,50%);
  left: 50%;
  transform: translate(-50%,-50%);


  @media screen and (min-width: 1440px) and (max-height: 800px){
    left: 55%;
    top: 42%;
    height: 90%;
  }


  @media screen and (max-width: 500px){
    left: 45%;
  }
  @media screen and (max-width: 360px){
    left: 38%;
  }
}


.circle{
  position: absolute;
  width:clamp(500px,120vw,1500px);
  height: clamp(500px,120vw,1500px);
  left: 60%;
  top: 40%;
  transform: translate(-50%, -50%);

  @media screen and(max-width: 500px) {
    top:clamp( 32%,70vw, 40%);

  }
}