@charset "utf-8";@import "STYLES/variables";
.sound{
  z-index: 20;

  &-wrapper{
    position: relative;
    padding-left: 380px;
    cursor: pointer;
    mix-blend-mode: difference;


    @media screen and (max-width: 769px) {
      padding-left:clamp(300px, 60vw, 340px);
    }

    &-icon{
        position: fixed;
        bottom: 34px;

      svg{


        g{
          opacity: 1;
          transition: all 0.75s;

          .soundWrapperIconOff{
            opacity: 0;
            transition: all 0.75s;

          }
          }
      }
      &.active{
        svg{

          g{
            opacity: 0.5;
            .soundWrapperIconOff{
              opacity: 1;
            }
          }
        }
      }

    }

    &-mobile{
      position: relative;
      mix-blend-mode: difference;
      z-index: 20;
      &-icon {
        position: fixed;
        top: 10px;

        svg{

          g{
            opacity: 1;
            transition: all 0.75s;

            .soundWrapperIconOff{
              opacity: 0;
              transition: all 0.75s;

            }
          }
        }
        &.active{
          svg{

            g{
              opacity: 0.5;
              .soundWrapperIconOff{
                opacity: 1;
              }
            }
          }
        }
      }

    }
  }


}