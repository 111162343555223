@charset "utf-8";@import "STYLES/variables";
@import "../../../scss/variables.scss";

.details{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: clamp(138px, 20vw, 400px) 0 clamp(118px, 20vw, 200px) 0;
  overflow-y: hidden !important;

  &-content{
    padding-bottom: 25px;

    &-title{
      margin-bottom: clamp(24px, 5vw, 64px);
      @include adaptH2;
      font-weight: 400;
      font-family: $fontTitle;

    }

  }

  @media screen and (max-width: 1023px) {
    justify-content: flex-start;
  }

  &-circles{
    &-red{
      position: absolute;
      width: 120%;
      height: clamp(500px, 100vw, 1200px);
      top: 7%;
      left: 75%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
    &-green{
      position: absolute;
      width: 120%;
      height: clamp(500px, 100vw, 1200px);
      top: 5%;
      left: 40%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }

    @media screen and (max-width: 640px) {
      &-red{
          top: 15%;
        left: 95%;
      }
      &-green{
        top: 35%;
        left: 20%;
      }
    }
  }
}
