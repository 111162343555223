@charset "utf-8";@import "STYLES/variables";
@import "../../../scss/variables.scss";

.banner {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;


  &-content {
    padding: 0 30px;
    z-index: 21;
    opacity: 0;
    text-align: left;
    transition-delay: 0.5s;
    transition-property: opacity;
    transition-duration: 0.5s;

    &.active{
      opacity: 1;
    }

    &-icon {
      margin-bottom: 50px;
      svg {
        width: clamp(100px, 20vw, 130px);
        height: clamp(100px, 20vw, 130px);
      }
    }

    &-text {
      h2 {
        @include adaptH2;
        position: relative;
        font-family: "Redcollar", sans-serif;
        font-weight: 400;
        z-index: 18;
        line-height: 110%;
        color: #0d0c0c;
        max-width: 900px;
        margin-bottom: 62px;
      }
    }

    button {
      font-weight: 400;
      font-family: $fontText;
      width: clamp(146px, 24vw, 227px);
      height: clamp(50px, 8vw, 77px);
      border: 1px solid #0d0c0c;
      border-radius: 64px;
      background-color: white;
      font-size: clamp(16px, 2.2vw, 32px);
      transition: all 0.33s;

      &:hover {
        background-color: #0d0c0c;
        color: #ffffff;
      }
    }
  }
}


.circle {
  position: absolute;
  width: 150%;
  height: 0;
  top: 50%;
  left: 70%;
  z-index: 21;
  pointer-events: none;
  transform: translate(-50%, -50%);

  &.active{
    height: clamp(110%, 150vw, 150%);
    transition-delay: 0.5s;
    transition-property: height;
    transition-duration: 0.5s;
  }
}

.canvas {
  position: fixed;
  width: 100vw;
  height: 100vh;

}

.preload{
  position: absolute;
  font-weight: 400;
  font-size:clamp(40px, 10vw, 128px);
  line-height: 90%;
  color: #0D0C0C;
  font-family: $fontTitle;
  height: clamp(60%, 90vw, 138%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: all 0.5s ease;

  &.active{
    opacity: 0;
    overflow: hidden;
  }
}


.scenesCanvas{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 14;
  pointer-events: none;
  overflow: hidden;
}




