@charset "utf-8";@import "STYLES/variables";
.section{

}

.video{
  display:block;
  position:relative;
  overflow:hidden;

  @include rect(62.7%);

  iframe{
    width:calc(100% + 2px);
    height:calc(100% + 2px);
    margin:-1px -1px 0 0;
    display:block;
  }
  &.active{
    .overlay{
      opacity:0;
      visibility:hidden;
    }
  }
}
.overlay, .poster, .container{
  width:100%;
  height:100%;
  display:block;
  position:absolute;
  top:0;
  left:0
}
.overlay{
  display:flex;
  align-items:center;
  justify-content:center;
  z-index:2;
  overflow:hidden;
  transition:opacity 0.5s ease, visibility 0.5s;
  background:no-repeat center top / cover;
}
.button{
  width:80px;
  height:80px;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius:50%;
  position:relative;
  z-index:2;
  background:#FFF;

  &:before{
    content:'';
    width:0;
    height:0;
    margin-left:8px;
    display:block;
    border:14px solid transparent;
    border-left:24px solid #FFF;
    border-right-width:0;
  }
}
.poster{
  will-change:transform;
  transition:transform 1s $ease;
}

@media screen and (min-width: 768px) {
  .video{
    &:before{
      padding-bottom:48%;
    }
  }

  .button{
    width:140px;
    height:140px;

    &:before{
      margin-left:12px;
      border:21px solid transparent;
      border-left:36px solid #FFF;
      border-right-width:0;
    }

    &:hover{
      & + .poster{
        transform:scale(1.05);
      }
    }
  }
}
