@charset "utf-8";@import "STYLES/variables";
.default{
  width:40px;
  height:40px;
  position:absolute;
  top:6px;
  right:6px;
  z-index:2;
  background:url('close.svg') no-repeat center;
}

.dark{
  composes: default;
  top:5px;
  right:5px;
  background-image:url('close_default.svg');
}


@media screen and (min-width: 768px) {
  .default{
    top:15px;
    right:15px;
  }
}