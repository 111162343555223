@charset "utf-8";@import "STYLES/variables";
@import "../../../scss/variables.scss";

.FooterWrapper{
  position: relative;
  width: 100%;
  max-width: 1620px;
  margin-top: 500px;
  padding-bottom: 120px;
  margin-bottom: 20px;
  overflow: hidden;

  @media screen and (max-width: 1023px){
    margin-top: 200px;
  }
  @media screen and (max-width: 640px){
    margin-top: 120px;
  }

  h1{
    position: relative;
    font-weight: 400;
    @include adaptH1;
    color: #0D0C0C;
    margin-bottom: 82px;
    font-family: $fontTitle;
    z-index: 18;
    @media screen and (min-width: 1440px) and (max-height: 800px){
      margin-bottom: 40px;
    }
    @media screen and(max-width: 450px) {
      margin-bottom: 22px;
    }
  }
  p{
    position: relative;
    font-family: $fontText;
    font-weight: 400;
    @include adaptP;
    line-height: 110%;
    color: #0D0C0C;
    padding-bottom: 343px;
    margin-left: clamp(12px,21vw,340px);
    max-width: clamp(340px,45vw,457px);
    z-index: 8;

    @media screen and (min-width: 1440px) and (max-height: 800px){
      margin-bottom: -50px;
    }
    
    @media screen and(max-width: 450px) {
      margin-left: clamp(12px,2vw,310px);
    }
  }
  .footerMadeBy {
    z-index: 18;
    position: absolute;
    bottom: 30px;
    right:50px;
    font-family: $fontText;
    font-weight: 400;
    font-size: clamp(14px, 2.5vw, 16px);
    color: #0D0C0C;
    cursor: pointer;

    @media screen and (max-width: 769px){
      bottom: 0;
    }
    @media screen and (max-width: 500px){
      right:0;
    }

    a{
      text-decoration: none;
      color: #0D0C0C;
      font-weight: 500;
      font-family: $fontTitle;
      margin: 0 10px;
      font-size: clamp(14px, 2.5vw, 16px);
    }

    &-rk {
      img {
        position: relative;
        top: 4px;
        margin-right: 6px;
      }
    }

    &-tamo {
      img {
        position: relative;
        top: 5px;
        margin-right: 6px;

      }
    }

    @media screen and (max-width: 500px) {
      a{
        margin: 0 5px;

        img {
          margin-right: 3px;
          max-height: 15px;
          max-width: 19px;
        }
      }
      &-rk {
        img {
          top: 0;
        }
      }

    }
  }

  .sideText{
    position: absolute;
    writing-mode: vertical-rl;
    font-weight: 400;
    font-family: $fontText;
    font-size:clamp(16px,3vw ,40px);
    line-height: 110%;
    text-transform: lowercase;
    color: #0D0C0C;
    transform: rotate(180deg);
    bottom: 0;
    margin-bottom: 70px;
  }

}

.soundIcon{
  @media screen and (max-width: 500px){
    display: none;
  }
}


.FooterScene{
  z-index: 18;
  overflow: hidden;
  position: absolute;
  width: 115%;
  height: clamp(30%, 78vw,180%);
  top: -65%;
  left: clamp(-15%, 70vw, -10%);


  @media screen and (min-width: 1440px) and (max-height: 800px){
    height: 190%;
    top: -73%;
    left: -13%;
  }


  @media screen and(max-width: 1200px){
    top: -40%;
  }
  @media screen and(max-width: 800px){
    top: -10%;
  }
  @media screen and(max-width: 500px){
    height: 80%;
    top: 0;
  }
  @media screen and(max-width: 360px){
    height: 70%;
  }

}

.CircleScene{
  position: absolute;
  width:clamp(500px,120vw,1100px);
  height: clamp(500px,120vw,1100px);
  left: 65%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media screen and(max-width: 800px){
    top: 60%;
  }
  @media screen and(max-width: 600px){
    top: 50%;
  }
}

